<template>
	<div class="home">
		<div class="straightforward-center">
			<el-row style="margin: 0 auto; width: 1350px;padding: 20px">
				<el-col :span="6">
					<contentLeft type="2" style='box-shadow: 0px 0px 6px #c4c4c4;'></contentLeft>
					<!-- 资料显示左边部分，公共部分 -->
				</el-col>
				<el-col :span="18" style="padding-bottom: 200px;background: #ffffff;box-shadow: 0px 0px 6px #c4c4c4;">
					<el-row class="content-right">
						<!-- 个人资料 -->
						<el-row class="con-base">
							<el-col>
								<b>
									<span style="color: #c43e1b;">|</span>基本信息
								</b>
							</el-col>
						</el-row>
						<el-row class="BasicInformation">
							<el-row :gutter="20" class="spacing">
								<el-col :span="12">
									<el-input :placeholder="session.account==''?'暂无邮箱':session.account" v-model="account" disabled="disabled">
										<template slot="prepend">账&nbsp;&nbsp;&nbsp;号</template>
									</el-input>
								</el-col>
							</el-row>
							<el-row :gutter="20" class="spacing">
								<el-col :span="12">
									<el-input :placeholder="session.nickname" disabled="disabled">
										<template slot="prepend">用户名</template>
									</el-input>
								</el-col>
								<el-col :span="12">
									<el-button class="modify_btn" @click="dialogVisible_open(1)">点击修改</el-button>
								</el-col>
							</el-row>
							<el-row :gutter="20" class="spacing">
								<el-col :span="12">
									<el-input :placeholder="session.phone==''?'请修改手机号':session.phone" disabled="disabled">
										<template slot="prepend">手机号</template>
									</el-input>
								</el-col>
								<el-col :span="12">
									<el-button class="modify_btn" @click="dialogVisible_open(2)">点击修改</el-button>
								</el-col>
							</el-row>
							<!--<el-row :gutter="20"  class="spacing">-->
							<!--<el-col :span="12"><el-input placeholder="请输入身份证" v-model="userID"><template slot="prepend">身份证</template></el-input></el-col>-->
							<!--<el-col :span="12"><el-button type="info">确认修改</el-button></el-col>-->
							<!--</el-row>-->

							<el-row :gutter="20" class="spacing">
								<el-col :span="12">
									<el-input placeholder="********" disabled="disabled">
										<template slot="prepend">密&nbsp;&nbsp;&nbsp;码</template>
									</el-input>
								</el-col>
								<el-col :span="12">
									<el-button class="modify_btn" @click="dialogVisible_open(3)">点击修改</el-button>
								</el-col>
							</el-row>
							<el-row :gutter="20" class="spacing">
								<el-col :span="12">
									<el-input :placeholder="session.email==''?'暂无邮箱':session.email" v-model="email" disabled="disabled">
										<template slot="prepend">邮&nbsp;&nbsp;&nbsp;箱</template>
									</el-input>
								</el-col>
							</el-row>
							<el-row :gutter="20" class="spacing">
								<el-col :span="12">
									<el-input :placeholder="session.type_role==1?'学生':(session.type_role==2?'老师':'企业')" v-model="type" disabled="disabled">
										<template slot="prepend">身&nbsp;&nbsp;&nbsp;份</template>
									</el-input>
								</el-col>
							</el-row>
						</el-row>
						<el-row class="con-detail" v-show="false">
							<!-- 详细信息-->
							<el-col>
								<b>
									<span>|</span>详细信息
								</b>
							</el-col>
						</el-row>
						<el-row class="con-bottom" v-show="false">
							<el-row v-for="(item,index) in selsedata" :key="index" style="margin-bottom: 15px;">
								<el-row :span="24">
									<el-col :span="2">{{item.datas}}</el-col>
									<el-col :span="22">
										<el-select v-model="item.value">
											<el-option v-for="items in  selsedata[index].options" :key="items.value" :label="items.label" :value="items.value"></el-option>
										</el-select>
									</el-col>
								</el-row>
							</el-row>
						</el-row>
					</el-row>
				</el-col>
				<el-dialog title="修改" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
					<el-form ref="form" v-show="is_type==1" :model="form" label-width="80px">
						<el-form-item label="用户名">
							<el-input v-model="form.name" :placeholder="session.nickname"></el-input>
						</el-form-item>
					</el-form>
					<el-form :inline="true" label-width="80px" v-show="is_type==2">
						<el-form-item label="手机号">
							<el-input v-model="form.phone" :placeholder="session.phone==''?'请填写手机号':session.phone"></el-input>
						</el-form-item>
						<el-form-item label="验证码">
							<el-input v-model="form.region" placeholder="请填写验证码"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="Get_captcha" :disabled="disabled1">获取验证码</el-button>
						</el-form-item>
					</el-form>
					<el-form ref="form" v-show="is_type==3" :model="form" label-width="80px">
						<el-form-item label="旧密码">
							<el-input v-model="form.old_password" placeholder="请填写旧密码"></el-input>
						</el-form-item>
						<el-form-item label="新密码">
							<el-input v-model="form.new_password" placeholder="请填写新密码"></el-input>
						</el-form-item>
						<el-form-item label="重复密码">
							<el-input v-model="form.repeat_password" placeholder="重复填写密码"></el-input>
						</el-form-item>
					</el-form>
					<span slot="footer" class="dialog-footer">
						<el-button @click="dialogVisible = false">取 消</el-button>
						<el-button type="primary" @click="dem_click">确 定</el-button>
					</span>
				</el-dialog>
			</el-row>
		</div>
	</div>
</template>
<script>
	import {
		requestSend_sms,
		requestCheck_sms,
		requestAccount_info,
		requestPassword_reset,
		requestAccount,
		requestaccount_exist,
	} from "../api/request";
	import contentLeft from "@/components/contentLeft.vue";
	export default {
		name: "Home",
		components: {
			contentLeft,
		},
		data() {
			return {
				session: "",
				isdisabled: false,
				typeCon: true,
				//用户名
				username: "",
				//手机号
				phone_number: "",
				//身份证号
				userID: "",
				//邮箱
				email: "",
				//账号
				account: "",
				//身份
				type: "",
				disabled1: false,
				form: {
					name: "",
					phone: "",
					old_password: "",
					new_password: "",
					repeat_password: "",
				},
				dialogVisible: false,
				is_type: 0,
				//密码
				password: "",
				selsedata: [{
						datas: "单位地址",
						value: "",
						options: [{
								value: "1",
								label: "1",
							},
							{
								value: "2",
								label: "2",
							},
							{
								value: "3",
								label: "3",
							},
							{
								value: "4",
								label: "4",
							},
						],
					},
					{
						datas: "现在住址",
						value: "",
						options: [{
								value: "1",
								label: "1",
							},
							{
								value: "2",
								label: "2",
							},
							{
								value: "3",
								label: "3",
							},
							{
								value: "4",
								label: "4",
							},
						],
					},
					{
						datas: "单位性质",
						value: "",
						options: [{
								value: "1",
								label: "1",
							},
							{
								value: "2",
								label: "2",
							},
							{
								value: "3",
								label: "3",
							},
							{
								value: "4",
								label: "4",
							},
						],
					},
					{
						datas: "企业性质",
						value: "",
						options: [{
								value: "1",
								label: "1",
							},
							{
								value: "2",
								label: "2",
							},
							{
								value: "3",
								label: "3",
							},
							{
								value: "4",
								label: "4",
							},
						],
					},
					{
						datas: "职位类型",
						value: "",
						options: [{
								value: "1",
								label: "1",
							},
							{
								value: "2",
								label: "2",
							},
							{
								value: "3",
								label: "3",
							},
							{
								value: "4",
								label: "4",
							},
						],
					},
					{
						datas: "信息来源",
						value: "",
						options: [{
								value: "1",
								label: "1",
							},
							{
								value: "2",
								label: "2",
							},
							{
								value: "3",
								label: "3",
							},
							{
								value: "4",
								label: "4",
							},
						],
					},
				],
			};
		},
		methods: {
			//判断是否在线
			isLogin1: function() {
				let session = this.Account.GetSession();
				if (session != null) {
					this.session = session;
				}
			},
			handleClose(done) {
				this.$confirm("确认关闭？")
					.then((_) => {
						done();
						console.log(1);
					})
					.catch((_) => {
						console.log(2);
					});
			},
			//点击修改打开弹窗
			dialogVisible_open(e) {
				this.dialogVisible = true;
				this.is_type = e;
			},
			//获取验证码
			Get_captcha() {
				let data_this = this,
					phone_zz = /^1[0-9]{10}$/;
				data_this.disabled1 = true;
				if (!phone_zz.test(data_this.form.phone)) {
					data_this.$message({
						message: "请输入正确的手机号",
						type: "error",
					});
					data_this.disabled1 = false;
					return;
				}
				let info = {
					account: data_this.form.phone,
				};
				requestaccount_exist(info).then((res) => {
					if (res.code == 0) {
						requestSend_sms(this.form.phone).then((res) => {
							if (res.code == 0) {
								data_this.$message({
									message: "发送成功",
									type: "success",
								});
							} else {
								data_this.disabled1 = false;
							}
						});
					} else {
						data_this.disabled1 = false;
					}
				});
			},
			//确定事件
			dem_click() {
				let data_this = this;
				if (this.is_type == 1) {
					var han = /^[\u4e00-\u9fa5]{2,6}$/;
					if (!han.test(data_this.form.name)) {
						data_this.$message({
							message: "请输入正确的姓名（纯文字2-6位）",
							type: "error",
						});
						return;
					}
					let info = {
						nickname: this.form.name,
						phone: this.session.phone,
						headpath: this.session.headpath,
						student_no: this.session.student_no,
						gender: this.session.gender,
					};
					requestAccount_info(info).then((res) => {
						if (res.code == 0) {
							data_this.$message({
								message: "修改成功",
								type: "success",
							});
							requestAccount().then((res) => {
								localStorage.setItem("session", JSON.stringify(res.ret));
								data_this.isLogin1();
							});
						}
					});
				} else if (this.is_type == 2) {
					let info = {
						mobile: this.form.phone,
						code: this.form.region,
					};
					let info1 = {
						nickname: this.session.nickname,
						phone: this.form.phone,
						headpath: this.session.headpath,
						student_no: this.session.student_no,
						gender: this.session.gender,
					};
					requestCheck_sms(info).then((res) => {
						if (res.code == 0) {
							requestAccount_info(info1).then((res) => {
								if (res.code == 0) {
									data_this.$message({
										message: "修改成功",
										type: "success",
									});
									requestAccount().then((res) => {
										localStorage.setItem("session", JSON.stringify(res.ret));
										data_this.isLogin1();
									});
								}
							});
						}
					});
				} else if (this.is_type == 3) {
					if (this.form.new_password == "") {
						data_this.$message({
							message: "密码不能为空",
							type: "error",
						});
						return;
					}
					if (this.form.new_password != this.form.repeat_password) {
						data_this.$message({
							message: "密码不一致",
							type: "error",
						});
						return;
					}
					if (this.form.new_password == this.form.old_password) {
						data_this.$message({
							message: "密码不能一致",
							type: "error",
						});
						return;
					}
					let info = {
						password: this.$md5(this.form.old_password),
						newpassword: this.$md5(this.form.new_password),
					};
					requestPassword_reset(info).then((res) => {
						if (res.code == 0) {
							data_this.$message({
								message: "修改成功",
								type: "success",
							});
							localStorage.setItem("session", JSON.stringify(res.ret));
							data_this.isLogin1();
						}
					});
				}
				data_this.dialogVisible = false;
			},
		},
		mounted() {
			if (this.Account.GetSession() == null) {
				this.$router.push({
					path: "/"
				});
				return;
			}
			this.isLogin1();
		},
	};
</script>
<style scoped>
	@import "../style/common.css";

	.straightforward-center {}

	.content {
		/* width: 1229px;
  width: 100%;
  height: 1000px;
  background-color: #d1e1f9;
  padding: 15px;
  display: flex;
  justify-content: flex-start; */
		width: 1350px;
		background-color: #d1e1f9;
		padding: 15px;
		display: flex;
		justify-content: flex-start;
		margin: 0 auto;
	}

	.content-right {
		/* height: 650px; */
		background-color: #fff;
		overflow: hidden;
		/* padding-top: 30px;
  padding-left: 70px; */
	}

	.con-base {
		height: 80px;
		line-height: 80px;
		border-bottom: 1px solid #efeff0;
	}

	.con-base span {
		color: #007aff;
	}

	.con-base b {
		margin-left: 50px;
		font-size: 16px;
	}

	.con-base b span,
	.con-detail b span {
		color: #007aff;
		margin-right: 7px;
	}

	.BasicInformation {
		margin-top: 40px;
		margin-left: 50px;
		margin-bottom: 74px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.BasicInformation label {
		font-size: 14px;
		color: #000;
		padding: 18px 0;
	}

	.con-detail {
		height: 46px;
		line-height: 30px;
		margin-top: 100px;
		border-bottom: 1px solid #efeff0;
	}

	.con-detail b {
		margin-left: 50px;
	}

	.con-bottom {
		margin-top: 41px;
		margin-left: 50px;
		margin-bottom: 50px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		/* height: 200px; */
		font-size: 14px;
		color: #000;
	}

	.con-bottom label {
		padding: 10px 0;
	}

	.con-bottom label select {
		width: 200px;
		height: 26px;
		margin-left: 10px;
	}

	.con-bottom button {
		width: 122px;
		height: 37px;
		border-radius: 15px solid #fff;
		margin-top: 84px;
		background-color: #007aff;
		border: 1px solid #007aff;
		border-radius: 15px;
		color: #fff;
	}

	.spacing {
		margin-bottom: 20px;
	}

	.modify_btn {
		width: 152px;
		height: 38px;
		border-color: #c43e1b;
		color: #c43e1b;
		border-radius: 10px;
	}
</style>
